export const google_places = {
    mounted() {
        if (typeof google === 'undefined' || !google.maps) {
            console.error('Google Maps API not loaded');
            return;
        }

        const DEFAULT_BOUNDS = new google.maps.LatLngBounds(
            { lat: 54.445319, lng: -1.594018 },
            { lat: 54.862270, lng: -1.090714 }
        );

        const OPTIONS = {
            bounds: DEFAULT_BOUNDS,
            types: ['geocode'],
            componentRestrictions: {
                country: 'gb'
            }
        }
  
        this.autocomplete = new google.maps.places.Autocomplete(
            this.$refs.autocomplete,
            OPTIONS
        );

        this.autocomplete.addListener('place_changed', () => {
            let place = this.autocomplete.getPlace();

            if (place.formatted_address) {
                this.search.location = place.formatted_address;
                this.search.latitude = place.geometry.location.lat().toFixed(5);
                this.search.longitude = place.geometry.location.lng().toFixed(5);
            }
        });
    }
}